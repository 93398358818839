export default {
  "还原": "还原",
  "退出编辑": "退出编辑",
  "完成批改": "完成批改",
  "提示": "提示",
  "暂不支持重新编辑 请谨慎提交": "暂不支持重新编辑 请谨慎提交",
  "确定": "确定",
  "退出编辑后，本次编辑内容将会丢失，确定退出？": "退出编辑后，本次编辑内容将会丢失，确定退出？",
  "取消拉黑": "取消拉黑",
  "黑名单还是空的": "黑名单还是空的",
  "取消": "取消",
  "接入": "接入",
  "接入成功": "接入成功",
  "操作失败": "操作失败",
  "删除会话": "删除会话",
  "系统通知": "系统通知",
  "请输入群名称": "请输入群名称",
  "搜索": "搜索",
  "刷新列表": "刷新列表",
  "创建会话": "创建会话",
  "清空筛选条件": "清空筛选条件",
  "在聊中": "在聊中",
  "请输入群名": "请输入群名",
  "刷新成功": "刷新成功",
  "没有找到该用户": "没有找到该用户",
  "输入userID后 按回车键": "输入userID后 按回车键",
  "取消禁言": "取消禁言",
  "禁言": "禁言",
  "请输入禁言时间": "请输入禁言时间",
  "修改群名片": "修改群名片",
  "请输入群名片": "请输入群名片",
  "踢出群组": "踢出群组",
  "不能设置空的群名片": "不能设置空的群名片",
  "修改成功": "修改成功",
  "详情": "详情",
  "添加群成员": "添加群成员",
  "群设置": "群设置",
  "置顶聊天": "置顶聊天",
  "查看更多": "查看更多",
  "群ID": "群ID",
  "群头像": "群头像",
  "群类型": "群类型",
  "群名称": "群名称",
  "群介绍": "群介绍",
  "群公告": "群公告",
  "申请加群方式": "申请加群方式",
  "自由加入": "自由加入",
  "需要验证": "需要验证",
  "禁止加群": "禁止加群",
  "群消息提示类型": "群消息提示类型",
  "接收消息并提示": "接收消息并提示",
  "接收消息但不提示": "接收消息但不提示",
  "屏蔽消息": "屏蔽消息",
  "我的群名片": "我的群名片",
  "全体禁言": "全体禁言",
  "转让群组": "转让群组",
  "新群主的userID": "新群主的userID",
  "退出群组": "退出群组",
  "解散群组": "解散群组",
  "取消全体禁言": "取消全体禁言",
  "普通群成员不能对全体禁言": "普通群成员不能对全体禁言",
  "退群成功": "退群成功",
  "该用户未设置昵称": "该用户未设置昵称",
  "将该用户加入黑名单": "将该用户加入黑名单",
  "加入黑名单": "加入黑名单",
  "将该用户移出黑名单": "将该用户移出黑名单",
  "移出黑名单": "移出黑名单",
  "聊天功能即将上线": "聊天功能即将上线",
  "敬请期待": "敬请期待",
  "查看详细信息": "查看详细信息",
  "没有更多了": "没有更多了",
  "回到最新位置": "回到最新位置",
  "筛选条件": "筛选条件",
  "时区": "时区",
  "剩余课时": "剩余课时",
  "班主任姓名": "班主任姓名",
  "重置": "重置",
  "筛选": "筛选",
  "姓名、学号、或邮箱": "姓名、学号、或邮箱",
  "学生": "学生",
  "中文剩余课时小于8": "中文剩余课时小于8",
  "中文剩余课时大于等于8": "中文剩余课时大于等于8",
  "数学剩余课时小于8": "数学剩余课时小于8",
  "数学剩余课时大于等于8": "数学剩余课时大于等于8",
  "请输入学生姓名": "请输入学生姓名",
  "没有找到该学生": "没有找到该学生",
  "暂无好友": "暂无好友",
  "群头像地址": "群头像地址",
  "群简介": "群简介",
  "加群方式": "加群方式",
  "自由加群": "自由加群",
  "群成员列表": "群成员列表",
  "请输入群成员 userID": "请输入群成员 userID",
  "立即创建": "立即创建",
  "群名片": "群名片",
  "入群时间": "入群时间",
  "禁言至": "禁言至",
  "发消息": "发消息",
  "按Enter发送消息": "按Enter发送消息",
  "开始直播后可以互动聊天哦！": "开始直播后可以互动聊天哦！",
  "不能发送空消息哦！": "不能发送空消息哦！",
  "礼物列表": "礼物列表",
  "直播中": "直播中",
  "暂无画面": "暂无画面",
  "观看直播": "观看直播",
  "暂停观看": "暂停观看",
  "声音": "声音",
  "不能自动播放": "不能自动播放",
  "标题有趣吸引人气": "标题有趣吸引人气",
  "开始直播": "开始直播",
  "结束直播": "结束直播",
  "麦克风": "麦克风",
  "摄像头关闭": "摄像头关闭",
  "页面-1": "页面-1",
  "j进度条备份": "j进度条备份",
  "编组-9": "编组-9",
  "编组-11": "编组-11",
  "形状结合": "形状结合",
  "直线-5": "直线-5",
  "摄像头": "摄像头",
  "手机扫码观看或复制链接分享给好友": "手机扫码观看或复制链接分享给好友",
  "复制链接": "复制链接",
  "分享直播": "分享直播",
  "复制成功": "复制成功",
  "复制失败": "复制失败",
  "头像": "头像",
  "小悟空": "小悟空",
  "你确认要停止接单吗": "你确认要停止接单吗",
  "停止接单": "停止接单",
  "会话列表": "会话列表",
  "群组列表": "群组列表",
  "好友列表": "好友列表",
  "黑名单列表": "黑名单列表",
  "群直播": "群直播",
  "退出": "退出",
  "登录": "登录",
  "摄像头未打开": "摄像头未打开",
  "撤回": "撤回",
  "删除": "删除",
  "对IM DEMO的评分和建议": "对IM DEMO的评分和建议",
  "已解决": "已解决",
  "未解决，我还有问题咨询": "未解决，我还有问题咨询",
  "为您提供专属服务，24小时在线": "为您提供专属服务，24小时在线",
  "上课情况": "上课情况",
  "本次共学习": "本次共学习",
  "获得奖励数": "获得奖励数",
  "答题率": "答题率",
  "查看课节详情": "查看课节详情",
  "您可以自行解析自定义消息": "您可以自行解析自定义消息",
  "单击下载": "单击下载",
  "点击查看详情": "点击查看详情",
  "处理": "处理",
  "处理加群申请": "处理加群申请",
  "处理结果：": "处理结果：",
  "同意": "同意",
  "拒绝": "拒绝",
  "附言：": "附言：",
  "请输入附言": "请输入附言",
  "取 消": "取 消",
  "确 定": "确 定",
  "单击播放": "单击播放",
  "您的浏览器不支持该格式的语音消息播放，请尝试更换浏览器，建议使用：谷歌浏览器": "您的浏览器不支持该格式的语音消息播放，请尝试更换浏览器，建议使用：谷歌浏览器",
  "视频出错，错误原因：": "视频出错，错误原因：",
  "直播已结束": "直播已结束",
  "您正在其它终端或者Web实例上开播，请勿重复开播！": "您正在其它终端或者Web实例上开播，请勿重复开播！",
  "发表情": "发表情",
  "发图片": "发图片",
  "发视频": "发视频",
  "发文件": "发文件",
  "发自定义消息": "发自定义消息",
  "小调查": "小调查",
  "语音通话": "语音通话",
  "视频通话": "视频通话",
  "对IM Web demo的建议和使用感受": "对IM Web demo的建议和使用感受",
  "评分": "评分",
  "建议": "建议",
  "请输入内容": "请输入内容",
  "按Enter发送消息，Ctrl+Enter换行": "按Enter发送消息，Ctrl+Enter换行",
  "请选择成员": "请选择成员",
  "消息存储失败": "消息存储失败",
  "不能发送空消息": "不能发送空消息",
  "正在呼叫...": "正在呼叫...",
  "通话已取消": "通话已取消",
  "通话已结束": "通话已结束",
  "已挂断": "已挂断",
  "编辑个人资料": "编辑个人资料",
  "头像地址(URL)": "头像地址(URL)",
  "昵称": "昵称",
  "性别": "性别",
  "男": "男",
  "女": "女",
  "不显示": "不显示",
  "头像应该是 Url 地址": "头像应该是 Url 地址",
  "访问官网": "访问官网",
  "小程序": "小程序",
  "直播电商解决方案": "直播电商解决方案",
  "请输入用户名": "请输入用户名",
  "请输入密码": "请输入密码",
  "请输入 userID": "请输入 userID",
  "登录成功": "登录成功",
  "登录失败：": "登录失败：",
  "多人处理": "多人处理",
  "等待上一步完成": "等待上一步完成",
  "待处理": "待处理",
  "已完成": "已完成",
  "已拒绝": "已拒绝",
  "(所有人完成，任务才可完成)": "(所有人完成，任务才可完成)",
  "1组组长(教务部)": "1组组长(教务部)",
  "教学管理员(教学部)": "教学管理员(教学部)",
  "回复": "回复",
  "流程已结束": "流程已结束",
  "基本信息": "基本信息",
  "学生名": "学生名",
  "英文名": "英文名",
  "年龄": "年龄",
  "常驻城市": "常驻城市",
  "所在时区": "所在时区",
  "中文班主任": "中文班主任",
  "数学辅导老师": "数学辅导老师",
  "中文课程顾问": "中文课程顾问",
  "数学课程顾问": "数学课程顾问",
  "查看详情": "查看详情",
  "体验课": "体验课",
  "回放链接": "回放链接",
  "入学学情": "入学学情",
  "拼音学习史": "拼音学习史",
  "孩子中文水平": "孩子中文水平",
  "家庭环境语": "家庭环境语",
  "汉字书写预期": "汉字书写预期",
  "国内中文学习史": "国内中文学习史",
  "曾用教材": "曾用教材",
  "海外中文学习史": "海外中文学习史",
  "曾用教材学到第几册": "曾用教材学到第几册",
  "家长教学期望": "家长教学期望",
  "授课风格偏好": "授课风格偏好",
  "家长画像与注意事项": "家长画像与注意事项",
  "中文程度-补充说明": "中文程度-补充说明",
  "上传头像": "上传头像",
  "上传": "上传",
  "今日": "今日",
  "本月": "本月",
  "设置": "设置",
  "退出登录": "退出登录",
  "已接入网络": "已接入网络",
  "当前网络不稳定": "当前网络不稳定",
  "当前网络不可用": "当前网络不可用",
  "新系统通知": "新系统通知",
  "线索来源": "线索来源",
  "请输入": "请输入",
  "线索阶段": "线索阶段",
  "请选择": "请选择",
  "查询": "查询",
  "待分配": "待分配",
  "待跟进": "待跟进",
  "跟进中": "跟进中",
  "已约课": "已约课",
  "CC待跟进": "CC待跟进",
  "CC跟进中": "CC跟进中",
  "发送订单": "发送订单",
  "财务确认到账": "财务确认到账",
  "已交接": "已交接",
  "无需发货": "无需发货",
  "待确认": "待确认",
  "待发货": "待发货",
  "已发货": "已发货",
  "已取消": "已取消",
  "转账": "转账",
  "支付宝": "支付宝",
  "微信": "微信",
  "信用卡": "信用卡",
  "银联": "银联",
  "零元": "零元",
  "其它": "其它",
  "审核中": "审核中",
  "已通过": "已通过",
  "已撤销": "已撤销",
  "中文": "中文",
  "数学": "数学",
  "新生报班": "新生报班",
  "续费": "续费",
  "补升": "补升",
  "赠课时": "赠课时",
  "待支付": "待支付",
  "已支付": "已支付",
  "已支付待确认": "已支付待确认",
  "已退款": "已退款",
  "学生续费": "学生续费",
  "学生补升": "学生补升",
  "定金": "定金",
  "国际运费": "国际运费",
  "学员升级": "学员升级",
  "活动寄送": "活动寄送",
  "序号": "序号",
  "线索名称": "线索名称",
  "手机号": "手机号",
  "学员": "学员",
  "推荐人": "推荐人",
  "分销大使": "分销大使",
  "邮箱": "邮箱",
  "线索类型": "线索类型",
  "阶段": "阶段",
  "科目": "科目",
  "未试听原因": "未试听原因",
  "创建时间": "创建时间",
  "组织数据": "组织数据",
  "本周": "本周",
  "本日": "本日",
  "订单状态": "订单状态",
  "转介绍线索": "转介绍线索",
  "我的组织": "我的组织",
  "组织(部门):": "组织(部门):",
  "时间范围": "时间范围",
  "同级组织": "同级组织",
  "成员数据": "成员数据",
  "员工姓名:": "员工姓名:",
  "员工邮箱:": "员工邮箱:",
  "姓名": "姓名",
  "续费订单量": "续费订单量",
  "续费金额/元": "续费金额/元",
  "补升订单量": "补升订单量",
  "补升金额/元": "补升金额/元",
  "组织": "组织",
  "组织负责人": "组织负责人",
  "当前学员": "当前学员",
  "去排课": "去排课",
  "课节列表": "课节列表",
  "课节日历": "课节日历",
  "班级名称": "班级名称",
  "班级类型": "班级类型",
  "授课老师": "授课老师",
  "课节状态": "课节状态",
  "学生课节状态": "学生课节状态",
  "上课日期(学生)": "上课日期(学生)",
  "上课日期(北京)": "上课日期(北京)",
  "重点": "重点",
  "回放": "回放",
  "作业": "作业",
  "报告": "报告",
  "教学重点": "教学重点",
  "班课": "班课",
  "私教课": "私教课",
  "课节名称": "课节名称",
  "所属班级": "所属班级",
  "课节类型": "课节类型",
  "上课时间(班级时区)": "上课时间(班级时区)",
  "上课时间(学生时区)": "上课时间(学生时区)",
  "操作": "操作",
  "待开课": "待开课",
  "旷课": "旷课",
  "预排课": "预排课",
  "回放课节": "回放课节",
  "课后作业": "课后作业",
  "学习报告": "学习报告",
  "题号": "题号",
  "正确答案": "正确答案",
  "学员答案": "学员答案",
  "最终答案": "最终答案",
  "课节资源": "课节资源",
  "名称": "名称",
  "类型": "类型",
  "查看": "查看",
  "暂无数据": "暂无数据",
  "复习": "复习",
  "预习": "预习",
  "姓名或学号": "姓名或学号",
  "申请人": "申请人",
  "审批状态": "审批状态",
  "变更类型": "变更类型",
  "审批详情": "审批详情",
  "待审核": "待审核",
  "关联学生": "关联学生",
  "申请详情": "申请详情",
  "审批结果": "审批结果",
  "审核通过": "审核通过",
  "不通过": "不通过",
  "撤销": "撤销",
  "撤销审批": "撤销审批",
  "确定撤销": "确定撤销",
  "关闭": "关闭",
  "您确定": "您确定",
  "确定撤销后，审核人员将不再会对该记录进行审批": "确定撤销后，审核人员将不再会对该记录进行审批",
  "提交": "提交",
  "请填写": "请填写",
  "实际课时转移": "实际课时转移",
  "课时转移成功": "课时转移成功",
  "课时转移": "课时转移",
  "课时返还": "课时返还",
  "课时赠送": "课时赠送",
  "已撤消": "已撤消",
  "我提交": "我提交",
  "待审批": "待审批",
  "已审批": "已审批",
  "变更学生": "变更学生",
  "课时变更类型": "课时变更类型",
  "申请变更日期": "申请变更日期",
  "审核人": "审核人",
  "课时审批": "课时审批",
  "学员状态审批": "学员状态审批",
  "订单审批": "订单审批",
  "提交人": "提交人",
  "订单号": "订单号",
  "订单类型": "订单类型",
  "订单审批类型": "订单审批类型",
  "审核状态": "审核状态",
  "订单已取消": "订单已取消",
  "商品详情": "商品详情",
  "商品名称": "商品名称",
  "折扣": "折扣",
  "数量": "数量",
  "超额赠课": "超额赠课",
  "订单折扣": "订单折扣",
  "超额赠额度": "超额赠额度",
  "订单编号": "订单编号",
  "提交日期": "提交日期",
  "冻结课程": "冻结课程",
  "延期开课": "延期开课",
  "期望变更状态": "期望变更状态",
  "延期/冻结时间": "延期/冻结时间",
  "申请日期": "申请日期",
  "加载中": "加载中",
  "已经到底了...": "已经到底了...",
  "查看全部": "查看全部",
  "数据简报": "数据简报",
  "实现课消": "实现课消",
  "续补达成": "续补达成",
  "线索达成": "线索达成",
  "推荐线索": "推荐线索",
  "累计退费学员": "累计退费学员",
  "本月不续费学员": "本月不续费学员",
  "任务及时率": "任务及时率",
  "服务分": "服务分",
  "服务人数": "服务人数",
  "暂未上传数据简报": "暂未上传数据简报",
  "学员分层": "学员分层",
  "续费分类": "续费分类",
  "快捷入口": "快捷入口",
  "学员作业": "学员作业",
  "输入学生姓名或学号": "输入学生姓名或学号",
  "作业状态": "作业状态",
  "上课时间": "上课时间",
  "未发布": "未发布",
  "未完成": "未完成",
  "部分完成": "部分完成",
  "学生姓名": "学生姓名",
  "课节时间": "课节时间",
  "提交时间": "提交时间",
  "正确率": "正确率",
  "课时信息": "课时信息",
  "申请变更": "申请变更",
  "剩余总课时": "剩余总课时",
  "已扣课时数": "已扣课时数",
  "伴学奖赠课": "伴学奖赠课",
  "购买课时": "购买课时",
  "推荐赠课": "推荐赠课",
  "手动变更课时": "手动变更课时",
  "分销赠课": "分销赠课",
  "购买赠课": "购买赠课",
  "社交平台分享赠课": "社交平台分享赠课",
  "入学分享赠课": "入学分享赠课",
  "小班课专属赠课": "小班课专属赠课",
  "剩余额度 = 总额度 - 已获分享额度": "剩余额度 = 总额度 - 已获分享额度",
  "分享赠课额度（自动计算）": "分享赠课额度（自动计算）",
  "分享赠课额度（调整）": "分享赠课额度（调整）",
  "其他": "其他",
  "其他信息": "其他信息",
  "创建人": "创建人",
  "学生所有人": "学生所有人",
  "当前学情": "当前学情",
  "中文-课程进度": "中文-课程进度",
  "主修课班型": "主修课班型",
  "周消耗课时": "周消耗课时",
  "主修课班级名称": "主修课班级名称",
  "上课时长": "上课时长",
  "当前级别": "当前级别",
  "最近完成的主修课": "最近完成的主修课",
  "中文-教学期望": "中文-教学期望",
  "听说": "听说",
  "读": "读",
  "写": "写",
  "课程进度": "课程进度",
  "作业难度": "作业难度",
  "作业量": "作业量",
  "中文-其他学情": "中文-其他学情",
  "授课语言": "授课语言",
  "教学互动": "教学互动",
  "作业完成度": "作业完成度",
  "行为习惯": "行为习惯",
  "中文-排课信息": "中文-排课信息",
  "入学时间（北京时间）": "入学时间（北京时间）",
  "无": "无",
  "首课时间（北京时间）": "首课时间（北京时间）",
  "补升权益": "补升权益",
  "阅读课（北京时间）": "阅读课（北京时间）",
  "数学-课程进度": "数学-课程进度",
  "数学-教学期望": "数学-教学期望",
  "数与代数": "数与代数",
  "几何与测量": "几何与测量",
  "推理与策略": "推理与策略",
  "应用与实战": "应用与实战",
  "统计与概率": "统计与概率",
  "数学-其他学情": "数学-其他学情",
  "数学-排课信息": "数学-排课信息",
  "学号": "学号",
  "生日": "生日",
  "常驻国家/地区": "常驻国家/地区",
  "入学年龄": "入学年龄",
  "城市": "城市",
  "当前年龄": "当前年龄",
  "学生币种": "学生币种",
  "母语": "母语",
  "中文普通话": "中文普通话",
  "英语": "英语",
  "日语": "日语",
  "韩语": "韩语",
  "法语": "法语",
  "德语": "德语",
  "印尼语": "印尼语",
  "手机": "手机",
  "推广大使": "推广大使",
  "接收": "接收",
  "公众号提醒": "公众号提醒",
  "家长喜欢的沟通方式": "家长喜欢的沟通方式",
  "电话": "电话",
  "短信": "短信",
  "邮件": "邮件",
  "各种方式都联系不上": "各种方式都联系不上",
  "服务信息": "服务信息",
  "数学助教": "数学助教",
  "账号信息": "账号信息",
  "Classin上课账号": "Classin上课账号",
  "Classin同步UUID": "Classin同步UUID",
  "学生状态(中文)": "学生状态(中文)",
  "学生状态": "学生状态",
  "学生状态(数学)": "学生状态(数学)",
  "学生可用时间": "学生可用时间",
  "我的时区": "我的时区",
  "每周一": "每周一",
  "请选择我的时区": "请选择我的时区",
  "每周二": "每周二",
  "每周三": "每周三",
  "每周四": "每周四",
  "每周五": "每周五",
  "每周六": "每周六",
  "每周天": "每周天",
  "请选择学生时区": "请选择学生时区",
  "邮寄地址": "邮寄地址",
  "收件人姓名": "收件人姓名",
  "收件人电话": "收件人电话",
  "收货国家/地区": "收货国家/地区",
  "州/省": "州/省",
  "街道": "街道",
  "邮政编码": "邮政编码",
  "保存编辑": "保存编辑",
  "确认订单寄送信息": "确认订单寄送信息",
  "订单": "订单",
  "创建于": "创建于",
  "客户": "客户",
  "收货人": "收货人",
  "收货人电话": "收货人电话",
  "学生画像": "学生画像",
  "学习风格": "学习风格",
  "视觉型": "视觉型",
  "听觉型": "听觉型",
  "体验型": "体验型",
  "兴趣爱好课外班": "兴趣爱好课外班",
  "绘画、舞蹈、声乐、表演等艺术类": "绘画、舞蹈、声乐、表演等艺术类",
  "钢琴、小提起等乐器类": "钢琴、小提起等乐器类",
  "编程、乐高、Stem等科技编程类": "编程、乐高、Stem等科技编程类",
  "数学、英文等基础学科类": "数学、英文等基础学科类",
  "游泳、网球、篮球等运动类": "游泳、网球、篮球等运动类",
  "其他Online课程": "其他Online课程",
  "绘画Online": "绘画Online",
  "乐器陪练": "乐器陪练",
  "数学Online": "数学Online",
  "英文Online": "英文Online",
  "编程Online": "编程Online",
  "学生画像补充": "学生画像补充",
  "家长画像": "家长画像",
  "学习负责人": "学习负责人",
  "妈妈": "妈妈",
  "爸爸": "爸爸",
  "爸爸妈妈都负责": "爸爸妈妈都负责",
  "职业状态": "职业状态",
  "家庭为主": "家庭为主",
  "职场精英": "职场精英",
  "不确定": "不确定",
  "互动活跃度": "互动活跃度",
  "热情互动": "热情互动",
  "常规沟通": "常规沟通",
  "偶尔回复": "偶尔回复",
  "基本不回复": "基本不回复",
  "家长影响力": "家长影响力",
  "明星级博主/自媒体，影响力卓越": "明星级博主/自媒体，影响力卓越",
  "群主/KOL，影响力较大": "群主/KOL，影响力较大",
  "推荐朋友较多，有一定影响力": "推荐朋友较多，有一定影响力",
  "常规": "常规",
  "沟通方式偏好": "沟通方式偏好",
  "开启": "开启",
  "沟通时间偏好": "沟通时间偏好",
  "家长画像补充": "家长画像补充",
  "希望加强练习": "希望加强练习",
  "保持不变": "保持不变",
  "希望适当减少": "希望适当减少",
  "希望快一点": "希望快一点",
  "希望慢一点": "希望慢一点",
  "希望难一点": "希望难一点",
  "希望简单一点": "希望简单一点",
  "希望少一点": "希望少一点",
  "希望多一点": "希望多一点",
  "其他/特殊需求": "其他/特殊需求",
  "俄语": "俄语",
  "粤语": "粤语",
  "泰语": "泰语",
  "西班牙语": "西班牙语",
  "新加坡英语": "新加坡英语",
  "阿拉伯语": "阿拉伯语",
  "高，乐于表达": "高，乐于表达",
  "中，需要老师引导": "中，需要老师引导",
  "低于50%，需关注": "低于50%，需关注",
  "低，基本不参与互动": "低，基本不参与互动",
  "超80%完成，优秀": "超80%完成，优秀",
  "80%～50%完成，良好": "80%～50%完成，良好",
  "准时出席": "准时出席",
  "偶尔缺席/迟到": "偶尔缺席/迟到",
  "高频缺席/迟到": "高频缺席/迟到",
  "入学时间(北京时间)": "入学时间(北京时间)",
  "首课时间(北京时间)": "首课时间(北京时间)",
  "阅读A班-北京时间周六08:00": "阅读A班-北京时间周六08:00",
  "阅读B班-北京时间周六16:00": "阅读B班-北京时间周六16:00",
  "入学日期(北京时间)": "入学日期(北京时间)",
  "中文-入学学情": "中文-入学学情",
  "家庭语言环境": "家庭语言环境",
  "学生画像/爱好/兴趣点": "学生画像/爱好/兴趣点",
  "中文-体验课信息": "中文-体验课信息",
  "中文-学习计划": "中文-学习计划",
  "科目类型": "科目类型",
  "级别（I）": "级别（I）",
  "级别（II）": "级别（II）",
  "级别（III）": "级别（III）",
  "班型": "班型",
  "上课频率（每周）": "上课频率（每周）",
  "当前授课老师": "当前授课老师",
  "数学-入学学情": "数学-入学学情",
  "孩子数学水平": "孩子数学水平",
  "优秀": "优秀",
  "良好": "良好",
  "一般": "一般",
  "参与有数学辅导班": "参与有数学辅导班",
  "有": "有",
  "参与有其他兴趣班": "参与有其他兴趣班",
  "数学-体验课信息": "数学-体验课信息",
  "数学-学习计划": "数学-学习计划",
  "更改首课时间": "更改首课时间",
  "学情信息": "学情信息",
  "入学日期（北京时间）": "入学日期（北京时间）",
  "回放连接": "回放连接",
  "入学教学期望": "入学教学期望",
  "测试级别": "测试级别",
  "分数": "分数",
  "入学在读年级": "入学在读年级",
  "数学入学年龄": "数学入学年龄",
  "体验课名称": "体验课名称",
  "体验课老师": "体验课老师",
  "体验课回放": "体验课回放",
  "课后点评": "课后点评",
  "的线索": "的线索",
  "新建线索": "新建线索",
  "拓科": "拓科",
  "多娃": "多娃",
  "去约课": "去约课",
  "复制约课链接": "复制约课链接",
  "约课": "约课",
  "发送至群": "发送至群",
  "正常": "正常",
  "公海": "公海",
  "回收站": "回收站",
  "线索待分配": "线索待分配",
  "线索待跟进": "线索待跟进",
  "线索跟进中": "线索跟进中",
  "线索已约课": "线索已约课",
  "CC发送订单": "CC发送订单",
  "CC财务确认到账": "CC财务确认到账",
  "普通线索": "普通线索",
  "学员拓科": "学员拓科",
  "一家多娃": "一家多娃",
  "外部线索": "外部线索",
  "中文班主任自建单": "中文班主任自建单",
  "Math辅导老师自建单": "Math辅导老师自建单",
  "新西兰转账": "新西兰转账",
  "跨国转账": "跨国转账",
  "国内转账": "国内转账",
  "现金": "现金",
  "银行卡": "银行卡",
  "进线类型": "进线类型",
  "来源": "来源",
  "销售负责人": "销售负责人",
  "约课负责人": "约课负责人",
  "所有人": "所有人",
  "的订单": "的订单",
  "寄送状态": "寄送状态",
  "输入订单号": "输入订单号",
  "新建订单": "新建订单",
  "取消订单": "取消订单",
  "下载invoice": "下载invoice",
  "申请财务到账": "申请财务到账",
  "商品": "商品",
  "寄送": "寄送",
  "收货地址": "收货地址",
  "物流公司": "物流公司",
  "物流编号": "物流编号",
  "支付信息": "支付信息",
  "财务审核人": "财务审核人",
  "审核时间": "审核时间",
  "财务确认状态": "财务确认状态",
  "支付方式": "支付方式",
  "付款时间": "付款时间",
  "付款账户持卡人": "付款账户持卡人",
  "支付渠道流水": "支付渠道流水",
  "财务周期": "财务周期",
  "财务审核备注": "财务审核备注",
  "付款凭证": "付款凭证",
  "转账汇率换算截图": "转账汇率换算截图",
  "退款信息": "退款信息",
  "退款商品": "退款商品",
  "退款数量": "退款数量",
  "退款总金额": "退款总金额",
  "退款状态": "退款状态",
  "退款原因": "退款原因",
  "退款完成时间": "退款完成时间",
  "退款截图": "退款截图",
  "订单信息": "订单信息",
  "FEDEX联邦": "FEDEX联邦",
  "顺丰": "顺丰",
  "专线": "专线",
  "中通": "中通",
  "支付失败": "支付失败",
  "商品标签": "商品标签",
  "订单金额": "订单金额",
  "个人信息": "个人信息",
  "数学班主任": "数学班主任",
  "微信OPENID": "微信OPENID",
  "ClassIn同步UUID": "ClassIn同步UUID",
  "学生状态-中文": "学生状态-中文",
  "学生状态-数学": "学生状态-数学",
  "发货地址": "发货地址",
  "修改手机号": "修改手机号",
  "保存": "保存",
  "原手机号": "原手机号",
  "新手机号": "新手机号",
  "修改邮箱": "修改邮箱",
  "原邮箱": "原邮箱",
  "新邮箱": "新邮箱",
  "班级": "班级",
  "下载": "下载",
  "生成时间": "生成时间",
  "家长微信昵称": "家长微信昵称",
  "用户影响力": "用户影响力",
  "沟通时间偏好(学生时间)": "沟通时间偏好(学生时间)",
  "中文分享链接": "中文分享链接",
  "数学分享链接": "数学分享链接",
  "分享链接": "分享链接",
  "群成员": "群成员",
  "搜索聊天记录": "搜索聊天记录",
  "刷新": "刷新",
  "(系统修复)": "(系统修复)",
  "操作员姓名": "操作员姓名",
  "类别": "类别",
  "说明": "说明",
  "日期": "日期",
  "备注": "备注",
  "来源信息": "来源信息",
  "科 目": "科 目",
  "变更方式": "变更方式",
  "增加": "增加",
  "减少": "减少",
  "变更课时数": "变更课时数",
  "请输入变更课时数": "请输入变更课时数",
  "上传截图": "上传截图",
  "上传图片": "上传图片",
  "备 注": "备 注",
  "请输入备注": "请输入备注",
  "请选择科目": "请选择科目",
  "请选择课时变更类型": "请选择课时变更类型",
  "请选择变更方式": "请选择变更方式",
  "成员管理": "成员管理",
  "请输入姓名": "请输入姓名",
  "请输入邮箱": "请输入邮箱",
  "请输入工号": "请输入工号",
  "职务": "职务",
  "工作性质": "工作性质",
  "账号状态": "账号状态",
  "他的学生": "他的学生",
  "工号": "工号",
  "入职日期": "入职日期",
  "全职": "全职",
  "兼职": "兼职",
  "禁用": "禁用",
  "班主任": "班主任",
  "班主任主管": "班主任主管",
  "班主任组长": "班主任组长",
  "教务": "教务",
  "教务主管": "教务主管",
  "查看学生信息": "查看学生信息",
  "增长活动": "增长活动",
  "分享海报": "分享海报",
  "福利官": "福利官",
  "(大使助理)": "(大使助理)",
  "剩余课时不足，请联系学生续课续费": "剩余课时不足，请联系学生续课续费",
  "资料": "资料",
  "学情": "学情",
  "阶段学习报告": "阶段学习报告",
  "阶段结业证书": "阶段结业证书",
  "跟进记录": "跟进记录",
  "聊天记录": "聊天记录",
  "跟进类型": "跟进类型",
  "已完成任务": "已完成任务",
  "超过30秒建议添加跟进记录": "超过30秒建议添加跟进记录",
  "顾问": "顾问",
  "描述": "描述",
  "课表": "课表",
  "课表详情": "课表详情",
  "今天": "今天",
  "节课": "节课",
  "明天": "明天",
  "近7天": "近7天",
  "聊天": "聊天",
  "待办": "待办",
  "新建任务": "新建任务",
  "确认首课时间": "确认首课时间",
  "稍后": "稍后",
  "请先完成排课后，再重新确认首课时间": "请先完成排课后，再重新确认首课时间",
  "分享赠课审批": "分享赠课审批",
  "学生分享赠课链接确认": "学生分享赠课链接确认",
  "任务详情": "任务详情",
  "课时": "课时",
  "余小静": "余小静",
  "学生信息": "学生信息",
  "当前班型": "当前班型",
  "当前科目": "当前科目",
  "当前科目类型": "当前科目类型",
  "流失前班主任": "流失前班主任",
  "流失前课程顾问": "流失前课程顾问",
  "流失信息": "流失信息",
  "流失类型": "流失类型",
  "流失主要原因": "流失主要原因",
  "流失主因II级": "流失主因II级",
  "流失主因III级": "流失主因III级",
  "其他流失原因": "其他流失原因",
  "流失去向": "流失去向",
  "不续费原因分类": "不续费原因分类",
  "不续费决定日期": "不续费决定日期",
  "退费原因分类": "退费原因分类",
  "退费决定日期": "退费决定日期",
  "退费状态": "退费状态",
  "已经消耗课时数": "已经消耗课时数",
  "试学期退费": "试学期退费",
  "入学标准": "入学标准",
  "挽单信息": "挽单信息",
  "班主任沟通记录": "班主任沟通记录",
  "顾问沟通记录": "顾问沟通记录",
  "教学沟通记录": "教学沟通记录",
  "其他备注原因": "其他备注原因",
  "挽单沟通描述": "挽单沟通描述",
  "挽单沟通记录": "挽单沟通记录",
  "沟通记录": "沟通记录",
  "关联订单": "关联订单",
  "退费金额": "退费金额",
  "退费币种": "退费币种",
  "删除学员流失记录": "删除学员流失记录",
  "确认": "确认",
  "学 号": "学 号",
  "学生ID": "学生ID",
  "当前级别I": "当前级别I",
  "流失主因II": "流失主因II",
  "流失主因III": "流失主因III",
  "全部退": "全部退",
  "部分退": "部分退",
  "已耗课时数": "已耗课时数",
  "本次退费是否属于试学期退费情况，请选择": "本次退费是否属于试学期退费情况，请选择",
  "是": "是",
  "否": "否",
  "退费学生在入学时是否符合该学科入学标准，请选择": "退费学生在入学时是否符合该学科入学标准，请选择",
  "符合": "符合",
  "不符合": "不符合",
  "其他原因备注": "其他原因备注",
  "关联订单号": "关联订单号",
  "请输入订单号，以逗号间隔": "请输入订单号，以逗号间隔",
  "请输入学生ID": "请输入学生ID",
  "请选择学号": "请选择学号",
  "请选择班型": "请选择班型",
  "请选择科目类型": "请选择科目类型",
  "请选择流失主要原因": "请选择流失主要原因",
  "请选择流失主因II": "请选择流失主因II",
  "请选择流失主因III": "请选择流失主因III",
  "请选择其他流失原因": "请选择其他流失原因",
  "请选择流失去向": "请选择流失去向",
  "请选择流失类型": "请选择流失类型",
  "请选择当前级别": "请选择当前级别",
  "请选择授课老师": "请选择授课老师",
  "请选择流失前课程顾问": "请选择流失前课程顾问",
  "请选择流失前班主任": "请选择流失前班主任",
  "请输入班主任沟通记录": "请输入班主任沟通记录",
  "请选择决定日期": "请选择决定日期",
  "请选择试用期退费": "请选择试用期退费",
  "请选择入学标准": "请选择入学标准",
  "请选择退费状态": "请选择退费状态",
  "请输入订单号": "请输入订单号",
  "请输入已消耗课时数": "请输入已消耗课时数",
  "请选择退款状态": "请选择退款状态",
  "流失状态": "流失状态",
  "组织(部门)": "组织(部门)",
  "重设": "重设",
  "新建": "新建",
  "编辑": "编辑",
  "完成": "完成",
  "确定完成": "确定完成",
  "未处理": "未处理",
  "发起审批": "发起审批",
  "请选择审批类型": "请选择审批类型",
  "请选择学生": "请选择学生",
  "请选择最迟解决时间（北京时间）": "请选择最迟解决时间（北京时间）",
  "操作提示": "操作提示",
  "确定提交": "确定提交",
  "暂不": "暂不",
  "请确认学生": "请确认学生",
  "更多信息": "更多信息",
  "离职": "离职",
  "请选择交接类型": "请选择交接类型",
  "请选择离职时间（北京时间）": "请选择离职时间（北京时间）",
  "班级交接周期": "班级交接周期",
  "请选择班级交接周期": "请选择班级交接周期",
  "紧急交接": "紧急交接",
  "常规交接": "常规交接",
  "请选择离职对外话术": "请选择离职对外话术",
  "学生升入更高级别（大级别），申请安排其他老师": "学生升入更高级别（大级别），申请安排其他老师",
  "老师转岗": "老师转岗",
  "老师进修，暂停教学工作": "老师进修，暂停教学工作",
  "其他（请补充在下列真实原因中）": "其他（请补充在下列真实原因中）",
  "请选择离职真实原因": "请选择离职真实原因",
  "其他真实原因": "其他真实原因",
  "请输入离职真实原因": "请输入离职真实原因",
  "我的任务-待办": "我的任务-待办",
  "班主任离职班级交接学生": "班主任离职班级交接学生",
  "交接学生": "交接学生",
  "交接类型": "交接类型",
  "交接班主任": "交接班主任",
  "请选择交接班主任": "请选择交接班主任",
  "选择交接时间段": "选择交接时间段",
  "分配学生": "分配学生",
  "确定删除该行吗?": "确定删除该行吗?",
  "新增": "新增",
  "新班主任": "新班主任",
  "选择科目": "选择科目",
  "分配学生名单": "分配学生名单",
  "首通处理时间": "首通处理时间",
  "离职交接": "离职交接",
  "普通交接": "普通交接",
  "请选择日期(北京时间)!": "请选择日期(北京时间)!",
  "请选择问题": "请选择问题",
  "请输入科目": "请输入科目",
  "请输入学生学号": "请输入学生学号",
  "请选择时间（北京时间）": "请选择时间（北京时间）",
  "审批原因": "审批原因",
  "请详细描述补充说明": "请详细描述补充说明",
  "新建大娃": "新建大娃",
  "请选择进线类型": "请选择进线类型",
  "请选择线索来源": "请选择线索来源",
  "请选择分销大使": "请选择分销大使",
  "请输入线索姓名": "请输入线索姓名",
  "家长沟通语言": "家长沟通语言",
  "补充资料": "补充资料",
  "未知": "未知",
  "请输入数字": "请输入数字",
  "微信老师": "微信老师",
  "请选择老师": "请选择老师",
  "继续添加": "继续添加",
  "上一步": "上一步",
  "下一步": "下一步",
  "中文(粤语)": "中文(粤语)",
  "社群转介绍活动": "社群转介绍活动",
  "分销渠道": "分销渠道",
  "取消新建": "取消新建",
  "继续填写": "继续填写",
  "发送邮件": "发送邮件",
  "插入邮件模板": "插入邮件模板",
  "洋洋": "洋洋",
  "确认发送": "确认发送",
  "搜索模板名称": "搜索模板名称",
  "级别": "级别",
  "插入模板": "插入模板",
  "模板名称": "模板名称",
  "插入此模板将覆盖当前邮件": "插入此模板将覆盖当前邮件",
  "插入": "插入",
  "已办": "已办",
  "抄送我": "抄送我",
  "我创建": "我创建",
  "我的待办": "我的待办",
  "任务说明": "任务说明",
  "完成建群后，该条任务自动变更成已完成": "完成建群后，该条任务自动变更成已完成",
  "跟家长打招呼做自我介绍，建立初步联系": "跟家长打招呼做自我介绍，建立初步联系",
  "请务必在学生首课结束后30天以内确认补升权益": "请务必在学生首课结束后30天以内确认补升权益",
  "b. 查看试听课回放形成初步客户画像": "b. 查看试听课回放形成初步客户画像",
  "c. 了解报名课包信息": "c. 了解报名课包信息",
  "a. 每次联系沟通情况，无论是否有效沟通，均须及时填写跟进记录，说明沟通情况": "a. 每次联系沟通情况，无论是否有效沟通，均须及时填写跟进记录，说明沟通情况",
  "c. 完成排课等待处理事项": "c. 完成排课等待处理事项",
  "请务必在72小时内完成排课": "请务必在72小时内完成排课",
  "请务必在学生首课开始前完成新生寄送订单确认": "请务必在学生首课开始前完成新生寄送订单确认",
  "与家长确认邮寄信息，并确认系统信息填写是否正确": "与家长确认邮寄信息，并确认系统信息填写是否正确",
  "请务必在首课结束后48小时内完成首课回访": "请务必在首课结束后48小时内完成首课回访",
  "请务必在48小时内完成任务": "请务必在48小时内完成任务",
  "c. 是否有疑问，有的话做对应异议处理和解答": "c. 是否有疑问，有的话做对应异议处理和解答",
  "d. 提醒补升机会，建议家长多关注和了解课程做考虑": "d. 提醒补升机会，建议家长多关注和了解课程做考虑",
  "a. 学情沟通，反馈和总结学情": "a. 学情沟通，反馈和总结学情",
  "b. 提醒家长补升机会和意向": "b. 提醒家长补升机会和意向",
  "c. 挖掘家长不参加的原因并做异议处理": "c. 挖掘家长不参加的原因并做异议处理",
  "请务必在每月25日之前完成任务": "请务必在每月25日之前完成任务",
  "b. 针对缺点做后续的学习规划调整，需要家长如何配合": "b. 针对缺点做后续的学习规划调整，需要家长如何配合",
  "请务必先确认前班主任是否完成交接备注并通知到家长，若未填写，请及时联系前班主任，并在跟进记录中做好备注": "请务必先确认前班主任是否完成交接备注并通知到家长，若未填写，请及时联系前班主任，并在跟进记录中做好备注",
  "交接入群后，请及时与家长打招呼，完成自我介绍与交接情况沟通": "交接入群后，请及时与家长打招呼，完成自我介绍与交接情况沟通",
  "请务必在72小时内完成任务": "请务必在72小时内完成任务",
  "批量入群": "批量入群",
  "输入任务名称": "输入任务名称",
  "任务状态": "任务状态",
  "搜索学生姓名或学号": "搜索学生姓名或学号",
  "选择离职交接或普通交接": "选择离职交接或普通交接",
  "交接学员详情": "交接学员详情",
  "任务": "任务",
  "离职原因": "离职原因",
  "离职时间": "离职时间",
  "处理人": "处理人",
  "截止时间": "截止时间",
  "处理状态": "处理状态",
  "处理时间": "处理时间",
  "任务类型": "任务类型",
  "阶段结课课节": "阶段结课课节",
  "课程类型": "课程类型",
  "交班合规": "交班合规",
  "请假原因": "请假原因",
  "交接班级": "交接班级",
  "任务描述": "任务描述",
  "收件人（学生）": "收件人（学生）",
  "订单创建人": "订单创建人",
  "缺课学生": "缺课学生",
  "缺课课节": "缺课课节",
  "缺课日期": "缺课日期",
  "任务截止时间": "任务截止时间",
  "相关课节": "相关课节",
  "提交人角色": "提交人角色",
  "课程顾问": "课程顾问",
  "截至时间": "截至时间",
  "处理结果": "处理结果",
  "首课时间": "首课时间",
  "课节": "课节",
  "前班主任": "前班主任",
  "开课时间": "开课时间",
  "状态": "状态",
  "任务名称": "任务名称",
  "任务时段": "任务时段",
  "执行人": "执行人",
  "完成任务": "完成任务",
  "冻结/延期结束时间": "冻结/延期结束时间",
  "补充说明": "补充说明",
  "待办任务": "待办任务",
  "已办任务": "已办任务",
  "抄送我任务": "抄送我任务",
  "我创建任务": "我创建任务",
  "当前节点处理人": "当前节点处理人",
  "学生资料": "学生资料",
  "个人信息-基本信息": "个人信息-基本信息",
  "个人信息-账号信息": "个人信息-账号信息",
  "个人信息-邮寄地址": "个人信息-邮寄地址",
  "服务信息-服务信息": "服务信息-服务信息",
  "服务信息-学生画像": "服务信息-学生画像",
  "服务信息-家长画像": "服务信息-家长画像",
  "当前学情-中文课程进度": "当前学情-中文课程进度",
  "当前学情-中文教学期望": "当前学情-中文教学期望",
  "当前学情-中文其他学情": "当前学情-中文其他学情",
  "当前学情-数学课程进度": "当前学情-数学课程进度",
  "当前学情-数学教学期望": "当前学情-数学教学期望",
  "当前学情-数学其他学情": "当前学情-数学其他学情",
  "入学学情-中文体验课信息": "入学学情-中文体验课信息",
  "入学学情-中文学习计划": "入学学情-中文学习计划",
  "入学学情-中文入学学情": "入学学情-中文入学学情",
  "入学学情-数学体验课信息": "入学学情-数学体验课信息",
  "入学学情-数学学习计划": "入学学情-数学学习计划",
  "入学学情-数学入学学情": "入学学情-数学入学学情",
  "班主任-跟进记录": "班主任-跟进记录",
  "李荷兰": "李荷兰",
  "顾问-跟进记录": "顾问-跟进记录",
  "互动作业": "互动作业",
  "纸质作业(家长明确要求)": "纸质作业(家长明确要求)",
  "Classin布置作业": "Classin布置作业",
  "不布置作业(家长明确要求)": "不布置作业(家长明确要求)",
  "未选择": "未选择",
  "请输入学号": "请输入学号",
  "订单创建时间": "订单创建时间",
  "实时汇率": "实时汇率",
  "币种": "币种",
  "订单学员": "订单学员",
  "截止有效期": "截止有效期",
  "添加跟进记录": "添加跟进记录",
  "跟进科目": "跟进科目",
  "跟进方式": "跟进方式",
  "电话结果": "电话结果",
  "身份职责": "身份职责",
  "学员信息": "学员信息",
  "入学课包": "入学课包",
  "新生权益": "新生权益",
  "教学期望": "教学期望",
  "学习计划": "学习计划",
  "看课反馈": "看课反馈",
  "首课感受": "首课感受",
  "级别进度": "级别进度",
  "作业提醒": "作业提醒",
  "寄送确认": "寄送确认",
  "分享推荐": "分享推荐",
  "学情分析": "学情分析",
  "建议：作近期作业完成情况，学习进步点待提高点等": "建议：作近期作业完成情况，学习进步点待提高点等",
  "补升方案介绍": "补升方案介绍",
  "建议：介绍优惠的唯一性，性价比，孩子学习情况等": "建议：介绍优惠的唯一性，性价比，孩子学习情况等",
  "学习规划制定": "学习规划制定",
  "建议：学习规划表，课频建议，XX级别学习目标，级别进度等": "建议：学习规划表，课频建议，XX级别学习目标，级别进度等",
  "补升意向": "补升意向",
  "家长回暖": "家长回暖",
  "参考：询问近期近况，孩子学情，可包含疫情（复课）、学校近况、天气等寒暄": "参考：询问近期近况，孩子学情，可包含疫情（复课）、学校近况、天气等寒暄",
  "沟通内容": "沟通内容",
  "参考：专题课推荐，利好政策同步等": "参考：专题课推荐，利好政策同步等",
  "复课计划": "复课计划",
  "复课时间": "复课时间",
  "阶段学情总结": "阶段学情总结",
  "家长反馈": "家长反馈",
  "出勤次数": "出勤次数",
  "缺席/迟到原因": "缺席/迟到原因",
  "学习规划": "学习规划",
  "学情反馈": "学情反馈",
  "参考：近期中文学习进度（出勤、上课专注度等学习情况）作业完成情况评价/老师批改作业情况反馈": "参考：近期中文学习进度（出勤、上课专注度等学习情况）作业完成情况评价/老师批改作业情况反馈",
  "核对学情": "核对学情",
  "身份介绍": "身份介绍",
  "遗留问题": "遗留问题",
  "转移类型": "转移类型",
  "转移学员学号": "转移学员学号",
  "请选择转移学生": "请选择转移学生",
  "接收学员学号": "接收学员学号",
  "请选择接收学生": "请选择接收学生",
  "折扣情况": "折扣情况",
  "课时转移记录": "课时转移记录",
  "课时转移原因": "课时转移原因",
  "旷课原因": "旷课原因",
  "补课安排": "补课安排",
  "质检学科": "质检学科",
  "质检类型": "质检类型",
  "质检来源": "质检来源",
  "质检判定": "质检判定",
  "奖惩分数": "奖惩分数",
  "奖惩金额": "奖惩金额",
  "请填写-500~500的整数数字": "请填写-500~500的整数数字",
  "质检判定时间": "质检判定时间",
  "责任人": "责任人",
  "质检人员": "质检人员",
  "质检描述": "质检描述",
  "模版：学员活泼好动，能听懂授课老师的指令，但开口比较少；建议家长多鼓励小朋友，整体难度是适合孩子的": "模版：学员活泼好动，能听懂授课老师的指令，但开口比较少；建议家长多鼓励小朋友，整体难度是适合孩子的",
  "交接备注": "交接备注",
  "参考：如对课件及首课老师不满，请在此备注原因，家长异议及沟通结果，其他": "参考：如对课件及首课老师不满，请在此备注原因，家长异议及沟通结果，其他",
  "参考最新转介绍活动": "参考最新转介绍活动",
  "家长有无意见反馈": "家长有无意见反馈",
  "家长意见反馈": "家长意见反馈",
  "参考：学员近期学习兴趣不高......": "参考：学员近期学习兴趣不高......",
  "参考：当前阶段的学习做简单的总结，从课频、孩子学习习惯、课堂表现等，描述学员本阶段进步点（某个细节）+夸细节反馈薄弱项+和妈妈探讨这薄弱项是不是确实不会 ": "参考：当前阶段的学习做简单的总结，从课频、孩子学习习惯、课堂表现等，描述学员本阶段进步点（某个细节）+夸细节反馈薄弱项+和妈妈探讨这薄弱项是不是确实不会 ",
  "互动作业提醒": "互动作业提醒",
  "作业完成度（准时完成、偶尔未提交/未完成、从不完成作业），作业中较为突出的优秀点，学员薄弱点，鼓励学员课后完成互动作业及时达到巩固效果 ": "作业完成度（准时完成、偶尔未提交/未完成、从不完成作业），作业中较为突出的优秀点，学员薄弱点，鼓励学员课后完成互动作业及时达到巩固效果 ",
  "是否发送报告": "是否发送报告",
  "学习建议": "学习建议",
  "参考：提供相应的学习资料或课程提升孩子薄弱项 ": "参考：提供相应的学习资料或课程提升孩子薄弱项 ",
  "课程预告": "课程预告",
  "参考：铺垫下阶段的学习内容，需要注意的重难点是什么": "参考：铺垫下阶段的学习内容，需要注意的重难点是什么",
  "是否邮寄教辅资料": "是否邮寄教辅资料",
  "参考：做后期学习规划+铺单": "参考：做后期学习规划+铺单",
  "学情交接": "学情交接",
  "课堂参与度": "课堂参与度",
  "出勤情况": "出勤情况",
  "分享参与": "分享参与",
  "不积极原因": "不积极原因",
  "教学服务信息": "教学服务信息",
  "老师旷课原因": "老师旷课原因",
  "其他原因": "其他原因",
  "是否同意注册试听": "是否同意注册试听",
  "不注册试听原因": "不注册试听原因",
  "约课是否成功": "约课是否成功",
  "未约课原因": "未约课原因",
  "参考：学员近期学习兴趣不高...... ": "参考：学员近期学习兴趣不高...... ",
  "学情总结": "学情总结",
  "参考：班主任描述孩子这5节课的进步点（某个细节）+夸细节、反馈薄弱项+和妈妈探讨这薄弱项是不是确实不会； ": "参考：班主任描述孩子这5节课的进步点（某个细节）+夸细节、反馈薄弱项+和妈妈探讨这薄弱项是不是确实不会； ",
  "是否确认不续费": "是否确认不续费",
  "不续费原因": "不续费原因",
  "不续费次要原因": "不续费次要原因",
  "提示：如三级分类均无，请详细描述次要原因": "提示：如三级分类均无，请详细描述次要原因",
  "是否取消所有课程": "是否取消所有课程",
  "班主任沟通概述": "班主任沟通概述",
  "提示：请概述家长不续费原因和续费沟通的次数及内容": "提示：请概述家长不续费原因和续费沟通的次数及内容",
  "是否清空赠课额度": "是否清空赠课额度",
  "是否补升": "是否补升",
  "家长异议": "家长异议",
  "提示：对比入学变化 ;总结学员5节课和单元测评学习状态以及学习进度（作业和上课表现） ;家长想法和感受（解决异议，预防退费） ;孩子最近感受和上课积极性 ;家长对课程的看法（有无意见和建议）  ": "提示：对比入学变化 ;总结学员5节课和单元测评学习状态以及学习进度（作业和上课表现） ;家长想法和感受（解决异议，预防退费） ;孩子最近感受和上课积极性 ;家长对课程的看法（有无意见和建议）  ",
  "提示： 预期管理 ;对比同年龄同级别学员学习（好的可以表扬，差一点的不用细说，可以说跟之前的进步，鼓励孩子） ;肯定鼓励表扬现阶段学习成果 ;保持目前学习节奏 ;针对薄弱点或者掌握不好的主题提供资料（handbook或者作业文档），加强复习巩固 ": "提示： 预期管理 ;对比同年龄同级别学员学习（好的可以表扬，差一点的不用细说，可以说跟之前的进步，鼓励孩子） ;肯定鼓励表扬现阶段学习成果 ;保持目前学习节奏 ;针对薄弱点或者掌握不好的主题提供资料（handbook或者作业文档），加强复习巩固 ",
  "提示：后面5节课课程难度变化，XX节课需要提前做XX准备": "提示：后面5节课课程难度变化，XX节课需要提前做XX准备",
  "提示：近2节课的学习做一个简单的总结从课堂表现课后作业；孩子前后课堂的具体的变化点）总结优点，缺点针对性做后续的学习规划调整，需要家长配合什么等": "提示：近2节课的学习做一个简单的总结从课堂表现课后作业；孩子前后课堂的具体的变化点）总结优点，缺点针对性做后续的学习规划调整，需要家长配合什么等",
  "跟进描述": "跟进描述",
  "跟进结果": "跟进结果",
  "意向度": "意向度",
  "下次跟进时间": "下次跟进时间",
  "下次跟进描述": "下次跟进描述",
  "同时完成任务": "同时完成任务",
  "未告知": "未告知",
  "有，在跟进描述中补充说明原因": "有，在跟进描述中补充说明原因",
  "否，在跟进描述中补充说明原因": "否，在跟进描述中补充说明原因",
  "已发送": "已发送",
  "未发送": "未发送",
  "已补升": "已补升",
  "放弃补升权益": "放弃补升权益",
  "价格": "价格",
  "学习效果": "学习效果",
  "无异议": "无异议",
  "未取消": "未取消",
  "已清空": "已清空",
  "未清空": "未清空",
  "已发起": "已发起",
  "未发起": "未发起",
  "学员原因-学员时间紧张": "学员原因-学员时间紧张",
  "学员原因-无数学补习需求": "学员原因-无数学补习需求",
  "学员原因-已有线下数学课": "学员原因-已有线下数学课",
  "学员原因-授课语言不匹配": "学员原因-授课语言不匹配",
  "学员原因-已有Online数学课": "学员原因-已有Online数学课",
  "课程产品-可试听时间不适合": "课程产品-可试听时间不适合",
  "课程产品-不认可1对多班型": "课程产品-不认可1对多班型",
  "课程产品-想学启蒙基础课": "课程产品-想学启蒙基础课",
  "不同意": "不同意",
  "成功": "成功",
  "失败": "失败",
  "学员原因-学员年龄不符合": "学员原因-学员年龄不符合",
  "未补课": "未补课",
  "已安排补课": "已安排补课",
  "忘记上课、忘记请假": "忘记上课、忘记请假",
  "操作系统无法登陆": "操作系统无法登陆",
  "停电、断网、电脑故障": "停电、断网、电脑故障",
  "突发原因：停电、生病、断网等": "突发原因：停电、生病、断网等",
  "积极参与": "积极参与",
  "一般，不积极": "一般，不积极",
  "不参与，很排斥": "不参与，很排斥",
  "已更新完善教学期望": "已更新完善教学期望",
  "已更新完善服务信息": "已更新完善服务信息",
  "对新老师满意，交接成功": "对新老师满意，交接成功",
  "对老师不满意，需更换老师": "对老师不满意，需更换老师",
  "投诉": "投诉",
  "流失": "流失",
  "质检": "质检",
  "优秀案例": "优秀案例",
  "质检抽检": "质检抽检",
  "约课顾问": "约课顾问",
  "教学部": "教学部",
  "客服渠道": "客服渠道",
  "奖励加分": "奖励加分",
  "有责扣罚": "有责扣罚",
  "无责不扣罚": "无责不扣罚",
  "已请假，系统原因未取消课节": "已请假，系统原因未取消课节",
  "已请假，人为原因未取消课节": "已请假，人为原因未取消课节",
  "不知道如何请假": "不知道如何请假",
  "忘记请假": "忘记请假",
  "待转移课时无折扣": "待转移课时无折扣",
  "待转移课时有折扣": "待转移课时有折扣",
  "中文课时不同学员互转": "中文课时不同学员互转",
  "Math课时不同学员互转": "Math课时不同学员互转",
  "中文转移数学": "中文转移数学",
  "数学转移中文": "数学转移中文",
  "无遗留问题": "无遗留问题",
  "有遗留问题待处理（请在跟进描述中详细备注）": "有遗留问题待处理（请在跟进描述中详细备注）",
  "已介绍": "已介绍",
  "暂未，在跟进描述中补充说明原因": "暂未，在跟进描述中补充说明原因",
  "和系统信息一致": "和系统信息一致",
  "不一致，请描述需补充的内容": "不一致，请描述需补充的内容",
  "低于50%，需关注（需在备注中记录沟通结果）": "低于50%，需关注（需在备注中记录沟通结果）",
  "低": "低",
  "中": "中",
  "高": "高",
  "学习效果不理想": "学习效果不理想",
  "价格不划算": "价格不划算",
  "授课老师不满意": "授课老师不满意",
  "消费观保守": "消费观保守",
  "其他（请在跟进描述中做好备注）": "其他（请在跟进描述中做好备注）",
  "准时完成": "准时完成",
  "偶尔未提交/未完成": "偶尔未提交/未完成",
  "从不完成作业": "从不完成作业",
  "已推广，转介绍活动": "已推广，转介绍活动",
  "未推广，在跟进描述中补充说明原因": "未推广，在跟进描述中补充说明原因",
  "已确认，并安排寄送": "已确认，并安排寄送",
  "未确认，在跟进描述中补充说明原因": "未确认，在跟进描述中补充说明原因",
  "作业提交方法已同步，已提醒": "作业提交方法已同步，已提醒",
  "当前级别合适，不需要调整": "当前级别合适，不需要调整",
  "当前级别不合适，需调整，在跟进描述中补充说明原因": "当前级别不合适，需调整，在跟进描述中补充说明原因",
  "对授课老师、课件教学内容均满意": "对授课老师、课件教学内容均满意",
  "对老师满意；对课件教学内容不满意": "对老师满意；对课件教学内容不满意",
  "对课件内容满意；对首课老师不满意": "对课件内容满意；对首课老师不满意",
  "均不满意，已提交审批": "均不满意，已提交审批",
  "魏老师": "魏老师",
  "班型（私教课、班课）已核对": "班型（私教课、班课）已核对",
  "入学级别已核对": "入学级别已核对",
  "上课频率（每周）已核对": "上课频率（每周）已核对",
  "学员可排课时间已核对": "学员可排课时间已核对",
  "授课风格已核对": "授课风格已核对",
  "教学特殊要求已核对": "教学特殊要求已核对",
  "确认主要学习负责人手机号和邮箱（App账号）": "确认主要学习负责人手机号和邮箱（App账号）",
  "确认回访沟通方式偏好（服务群文字or语音电话or邮件）": "确认回访沟通方式偏好（服务群文字or语音电话or邮件）",
  "已全部核对并补充，系统记录已校准并补充": "已全部核对并补充，系统记录已校准并补充",
  "部分未核对补充，在跟进描述中补充未核对部分": "部分未核对补充，在跟进描述中补充未核对部分",
  "未核对，在跟进记录中补充原因": "未核对，在跟进记录中补充原因",
  "悟空学员中心App/Web介绍，引导下载App": "悟空学员中心App/Web介绍，引导下载App",
  "新生补升权益": "新生补升权益",
  "班课调课/私教课请假规则": "班课调课/私教课请假规则",
  "退费规则": "退费规则",
  "24H在线客服介绍-App IM功能": "24H在线客服介绍-App IM功能",
  "已核对入学课时，含购买课时、购买赠课": "已核对入学课时，含购买课时、购买赠课",
  "已核对分享赠课额度及规则": "已核对分享赠课额度及规则",
  "基本信息已全部核对并补充": "基本信息已全部核对并补充",
  "上课账号已核对（App账号，上课提醒接收）": "上课账号已核对（App账号，上课提醒接收）",
  "未完成核对，后续补充": "未完成核对，后续补充",
  "暂存草稿": "暂存草稿",
  "仍要关闭": "仍要关闭",
  "逾期": "逾期",
  "查看课表": "查看课表",
  "去补升": "去补升",
  "建群": "建群",
  "确认订单": "确认订单",
  "交接入群": "交接入群",
  "当前账号暂无权限访问本系统": "当前账号暂无权限访问本系统",
  "你可以去": "你可以去",
  "修改": "修改",
  "做一做": "做一做",
  "主观题图片": "主观题图片",
  "学生提交内容": "学生提交内容",
  "学生主观题图片": "学生主观题图片",
  "老师评分": "老师评分",
  "点击输入老师评语": "点击输入老师评语",
  "完成批阅": "完成批阅",
  "学生还未提交作业": "学生还未提交作业",
  "报告还未提交": "报告还未提交",
  "题目序号": "题目序号",
  "题干": "题干",
  "错误次数": "错误次数",
  "答题时间": "答题时间",
  "AI分析": "AI分析",
  "正确": "正确",
  "绘本语句": "绘本语句",
  "朗读得分": "朗读得分",
  "朗读语音": "朗读语音",
  "历史详情": "历史详情",
  "修改题目": "修改题目",
  "支持MP3格式，文件不超过5分钟，大小不超过5M": "支持MP3格式，文件不超过5分钟，大小不超过5M",
  "上传音频": "上传音频",
  "图片最多5张，每张大小不超过3M": "图片最多5张，每张大小不超过3M",
  "视频最多2个，每个大小不超过50M": "视频最多2个，每个大小不超过50M",
  "上传视频": "上传视频",
  "文件最多2个，支持PDF，每个大小不超过50M": "文件最多2个，支持PDF，每个大小不超过50M",
  "上传文件": "上传文件",
  "保存并发布": "保存并发布",
  "确认完成": "确认完成",
  "的补升情况，确认后该任务将标记为已完成": "的补升情况，确认后该任务将标记为已完成",
  "已购买补升": "已购买补升",
  "家长已完成支付，且财务已确认到账的": "家长已完成支付，且财务已确认到账的",
  "已核实该学员放弃补升": "已核实该学员放弃补升",
  "多次与家长沟通后，家长依然坚持放弃补升权益的": "多次与家长沟通后，家长依然坚持放弃补升权益的",
  "去沟通": "去沟通",
  "你已成功创建与学生": "你已成功创建与学生",
  "1.完成自我介绍": "1.完成自我介绍",
  "2.与家长约定「新生首通」的时间": "2.与家长约定「新生首通」的时间",
  "补升超期提醒": "补升超期提醒",
  "立刻前往": "立刻前往",
  "稍后前往": "稍后前往",
  "你已成功完成学生": "你已成功完成学生",
  "课程推荐": "课程推荐",
  "中文海报": "中文海报",
  "数学海报": "数学海报",
  "点击生成": "点击生成",
  "的专属海报": "的专属海报",
  "生成专属海报": "生成专属海报",
  "批量下载": "批量下载",
  "开始下载": "开始下载",
  "请先选择要下载海报的学生": "请先选择要下载海报的学生",
  "已选": "已选",
  "人": "人",
  "海报详情": "海报详情",
  "复制": "复制",
  "唯一键": "唯一键",
  "请填写唯一键": "请填写唯一键",
  "角色名称": "角色名称",
  "请填写角色名称": "请填写角色名称",
  "备注说明": "备注说明",
  "拥有权限": "拥有权限",
  "全选": "全选",
  "请从列表中选择班课插班": "请从列表中选择班课插班",
  "留在原班看回放": "留在原班看回放",
  "调入": "调入",
  "课节人数": "课节人数",
  "班课插班": "班课插班",
  "排课列表": "排课列表",
  "请选择课节状态": "请选择课节状态",
  "星 期": "星 期",
  "请选择星期": "请选择星期",
  "排课": "排课",
  "批量取消": "批量取消",
  "批量修改助教": "批量修改助教",
  "调整时间": "调整时间",
  "修改授课老师": "修改授课老师",
  "调整课节星期": "调整课节星期",
  "(代课)": "(代课)",
  "调级": "调级",
  "调时间": "调时间",
  "学生列表": "学生列表",
  "添加学生": "添加学生",
  "挪出": "挪出",
  "修改课节": "修改课节",
  "助教老师": "助教老师",
  "请选择助教老师": "请选择助教老师",
  "上台人数": "上台人数",
  "上台人数(1-12数字)": "上台人数(1-12数字)",
  "调整课节级别": "调整课节级别",
  "将原级别": "将原级别",
  "新级别": "新级别",
  "请选择新级别": "请选择新级别",
  "是否将之后的所有课节": "是否将之后的所有课节",
  "按新级别重排": "按新级别重排",
  "取消排课": "取消排课",
  "确定要取消": "确定要取消",
  "请选择取消原因": "请选择取消原因",
  "请输入其他原因": "请输入其他原因",
  "新授课老师": "新授课老师",
  "请选择新授课老师": "请选择新授课老师",
  "是否代课": "是否代课",
  "临时代课": "临时代课",
  "长期交接(将之后课节都更换为此老师)": "长期交接(将之后课节都更换为此老师)",
  "原因": "原因",
  "是否将之后的所有课节都换为此老师": "是否将之后的所有课节都换为此老师",
  "调整课节时间": "调整课节时间",
  "新时间": "新时间",
  "请选择时间间隔": "请选择时间间隔",
  "按新时间间隔重排": "按新时间间隔重排",
  "请选择学生(支持学号，姓名搜索)": "请选择学生(支持学号，姓名搜索)",
  "开始日期": "开始日期",
  "将": "将",
  "选择新时间": "选择新时间",
  "新课节星期": "新课节星期",
  "请选择课节星期": "请选择课节星期",
  "新课节时分": "新课节时分",
  "学生容量": "学生容量",
  "班级时区": "班级时区",
  "课程名称": "课程名称",
  "开课级别": "开课级别",
  "学生数量": "学生数量",
  "星期": "星期",
  "课节时长": "课节时长",
  "插班生": "插班生",
  "调出生": "调出生",
  "状态原因": "状态原因",
  "国家/地区": "国家/地区",
  "开始时间": "开始时间",
  "付费状态": "付费状态",
  "周一": "周一",
  "周二": "周二",
  "周三": "周三",
  "周四": "周四",
  "周五": "周五",
  "周六": "周六",
  "周日": "周日",
  "请输入课节名称": "请输入课节名称",
  "请选择助教": "请选择助教",
  "请输入上台人数(1-12整数)": "请输入上台人数(1-12整数)",
  "删除学生": "删除学生",
  "班级容量": "班级容量",
  "请输入班级容量(1-1000数字)": "请输入班级容量(1-1000数字)",
  "请输入上台人数(1-12数字)": "请输入上台人数(1-12数字)",
  "课程": "课程",
  "班主任老师": "班主任老师",
  "请输入班级名称": "请输入班级名称",
  "请选择班级类型": "请选择班级类型",
  "请选择课程": "请选择课程",
  "请选择时区": "请选择时区",
  "请选择班主任老师": "请选择班主任老师",
  "一类级别": "一类级别",
  "二类级别": "二类级别",
  "排课周期": "排课周期",
  "剩余课节": "剩余课节",
  "全部": "全部",
  "等于0": "等于0",
  "大于0": "大于0",
  "大于0 小于5": "大于0 小于5",
  "大于0 小于10": "大于0 小于10",
  "大于0 小于20": "大于0 小于20",
  "新建班级": "新建班级",
  "结课": "结课",
  "下一课信息": "下一课信息",
  "课程科目": "课程科目",
  "排课类型": "排课类型",
  "新排课": "新排课",
  "加课": "加课",
  "开始课节": "开始课节",
  "请选择开始课节": "请选择开始课节",
  "结束课节": "结束课节",
  "请选择结束课节": "请选择结束课节",
  "课节数": "课节数",
  "选择开课日期": "选择开课日期",
  "开始": "开始",
  "结束": "结束",
  "老师属性": "老师属性",
  "授课风格": "授课风格",
  "能力星级": "能力星级",
  "可排级别": "可排级别",
  "指定老师": "指定老师",
  "老师姓名": "老师姓名",
  "搜索老师": "搜索老师",
  "老师列表": "老师列表",
  "教师简介": "教师简介",
  "暂无内容": "暂无内容",
  "教学理念": "教学理念",
  "请勿泄露以下信息": "请勿泄露以下信息",
  "暂无课节回放": "暂无课节回放",
  "课节回放": "课节回放",
  "考核影像记录": "考核影像记录",
  "老师课表": "老师课表",
  "(已签约)": "(已签约)",
  "排课结果": "排课结果",
  "设置助教": "设置助教",
  "确认排课": "确认排课",
  "跳过该时间,完成排课": "跳过该时间,完成排课",
  "取消本次排课": "取消本次排课",
  "老师可用时间": "老师可用时间",
  "排课时间": "排课时间",
  "请选择开始日期": "请选择开始日期",
  "请选择排课周期": "请选择排课周期",
  "发单课节信息": "发单课节信息",
  "相关课节(北京)": "相关课节(北京)",
  "期望老师授课风格": "期望老师授课风格",
  "期望老师性别": "期望老师性别",
  "发单有效期": "发单有效期",
  "发单备注": "发单备注",
  "立即发单": "立即发单",
  "放弃": "放弃",
  "确定发此": "确定发此",
  "紧急代课单": "紧急代课单",
  "发单后，授课老师将进行抢单；授课老师抢单前您可取消该单": "发单后，授课老师将进行抢单；授课老师抢单前您可取消该单",
  "30分钟": "30分钟",
  "1小时": "1小时",
  "2小时": "2小时",
  "3小时": "3小时",
  "6小时": "6小时",
  "12小时": "12小时",
  "24小时": "24小时",
  "发单类型": "发单类型",
  "待接单": "待接单",
  "已接单": "已接单",
  "超时已取消": "超时已取消",
  "抢单结果": "抢单结果",
  "确定取消": "确定取消",
  "确定取消后，授课老师将不会再收到该单的通知": "确定取消后，授课老师将不会再收到该单的通知",
  "排课单": "排课单",
  "紧急排课单": "紧急排课单",
  "抢单人(授课老师)": "抢单人(授课老师)",
  "请假学生": "请假学生",
  "请假状态": "请假状态",
  "调课": "调课",
  "请假课节": "请假课节",
  "开课日期": "开课日期",
  "是否调课": "是否调课",
  "申请时间": "申请时间",
  "放弃调课": "放弃调课",
  "插班": "插班",
  "请假": "请假",
  "请填写原因(必填)": "请填写原因(必填)",
  "请填写原因": "请填写原因",
  "私教课紧急请假": "私教课紧急请假",
  "出勤": "出勤",
  "迟到": "迟到",
  "早退": "早退",
  "取消学生": "取消学生",
  "自动更新之后章节": "自动更新之后章节",
  "体验课可用": "体验课可用",
  "标准课可用": "标准课可用",
  "通用时间": "通用时间",
  "已排课": "已排课",
  "不可用": "不可用",
  "小时": "小时",
  "「总工作时长」为可用时间与已排课时间总和（去重），再减去重合的不可用时间": "「总工作时长」为可用时间与已排课时间总和（去重），再减去重合的不可用时间",
  "添加不可用时间（春节）": "添加不可用时间（春节）",
  "反馈已提交": "反馈已提交",
  "您可随时前往": "您可随时前往",
  "查看进度": "查看进度",
  "确认修改课节": "确认修改课节",
  "请确认课节进度是否正确，并严格按照该进度上课": "请确认课节进度是否正确，并严格按照该进度上课",
  "设置筛选": "设置筛选",
  "请选择筛选项": "请选择筛选项",
  "删除此行": "删除此行",
  "自定义字段": "自定义字段",
  "搜索想要的字段": "搜索想要的字段",
  "当前列表展示": "当前列表展示",
  "课时变更": "课时变更",
  "变更原因": "变更原因",
  "转入学员": "转入学员",
  "请选择学员": "请选择学员",
  "转入科目": "转入科目",
  "转移数量": "转移数量",
  "补偿课时": "补偿课时",
  "分享赠课额度": "分享赠课额度",
  "转移额度": "转移额度",
  "课时数量": "课时数量",
  "学员状态变更": "学员状态变更",
  "变更学员": "变更学员",
  "变更状态科目": "变更状态科目",
  "当前状态(变更前)": "当前状态(变更前)",
  "变更状态为": "变更状态为",
  "延期/冻结开始日期": "延期/冻结开始日期",
  "延期/冻结结束日期": "延期/冻结结束日期",
  "线索学员": "线索学员",
  "线索详情": "线索详情",
  "所属科目": "所属科目",
  "线索关联学员": "线索关联学员",
  "线索约课": "线索约课",
  "订单确认页": "订单确认页",
  "选择订单商品": "选择订单商品",
  "核对订单信息": "核对订单信息",
  "确认无误，立即下单": "确认无误，立即下单",
  "学员订单": "学员订单",
  "订单商品": "订单商品",
  "添加商品": "添加商品",
  "无效价格": "无效价格",
  "订单备注": "订单备注",
  "订单有效期": "订单有效期",
  "提交订单": "提交订单",
  "返回上一页": "返回上一页",
  "选择商品": "选择商品",
  "2天": "2天",
  "3天": "3天",
  "4天": "4天",
  "5天": "5天",
  "6天": "6天",
  "7天": "7天",
  "优惠": "优惠",
  "税": "税",
  "小计": "小计",
  "赠课": "赠课",
  "L系列悟空宝盒": "L系列悟空宝盒",
  "G系列悟空宝盒": "G系列悟空宝盒",
  "S系列悟空宝盒": "S系列悟空宝盒",
  "团购": "团购",
  "专题课": "专题课",
  "上传类型：": "上传类型：",
  "上传数据": "上传数据",
  "上传类型": "上传类型",
  "选择文件": "选择文件",
  "数据上传成功": "数据上传成功",
  "数据上传失败": "数据上传失败",
  "点击下载失败文件，修改后可重新上传": "点击下载失败文件，修改后可重新上传",
  "忽略并关闭": "忽略并关闭",
  "下载失败文件": "下载失败文件",
  "文件名": "文件名",
  "上传结果": "上传结果",
  "上传人": "上传人",
  "数据上传时间": "数据上传时间",
  "数据更新时间": "数据更新时间",
  "班主任业务数据": "班主任业务数据",
  "申请退款": "申请退款",
  "退款课时明细": "退款课时明细",
  "编辑寄送": "编辑寄送",
  "确认退款信息": "确认退款信息",
  "退款金额": "退款金额",
  "退款备注": "退款备注",
  "课件": "课件",
  "教材作业": "教材作业",
  "课程体系其他": "课程体系其他",
  "老师教学/服务": "老师教学/服务",
  "班主任服务": "班主任服务",
  "顾问服务": "顾问服务",
  "系统": "系统",
  "规则": "规则",
  "学生原因": "学生原因",
  "家长原因": "家长原因",
  "竞品": "竞品",
  "家长拒绝进一步沟通": "家长拒绝进一步沟通",
  "课时类型": "课时类型",
  "剩余课时或额度": "剩余课时或额度",
  "可退课时或额度": "可退课时或额度",
  "分享赠课": "分享赠课",
  "已批准": "已批准",
  "花果": "花果",
  "学员中心APP": "学员中心APP",
  "学员中心小程序": "学员中心小程序",
  "赠课数量": "赠课数量",
  "审批时间": "审批时间",
  "分享赠课记录": "分享赠课记录",
  "变更记录": "变更记录",
  "总课时": "总课时",
  "暂无已扣课时": "暂无已扣课时",
  "暂无补偿课时": "暂无补偿课时",
  "剩余/总额度": "剩余/总额度",
  "当前学员线索": "当前学员线索",
  "学员推荐线索": "学员推荐线索",
  "状态变更": "状态变更",
  "中文昵称": "中文昵称",
  "英文昵称": "英文昵称",
  "修改classin账号": "修改classin账号",
  "将原classin": "将原classin",
  "账号修改为": "账号修改为",
  "新classin账号": "新classin账号",
  "区号": "区号",
  "修改昵称": "修改昵称",
  "原中文昵称": "原中文昵称",
  "跟进": "跟进",
  "线索": "线索",
  "分享": "分享",
  "中文调班课表": "中文调班课表",
  "数学调班课表": "数学调班课表",
  "更多": "更多",
  "学员任务": "学员任务",
  "更新首课": "更新首课",
  "中文首课": "中文首课",
  "数学首课": "数学首课",
  "近30天作业完成率": "近30天作业完成率",
  "近30天出勤率": "近30天出勤率",
  "学员课表": "学员课表",
  "今日课节": "今日课节",
  "服务": "服务",
  "学习": "学习",
  "分层": "分层",
  "更新首课时间": "更新首课时间",
  "国别号": "国别号",
  "中文学生状态": "中文学生状态",
  "中文剩余课时": "中文剩余课时",
  "中文剩余额度": "中文剩余额度",
  "中文当前级别": "中文当前级别",
  "中文顾问": "中文顾问",
  "中文推荐赠课": "中文推荐赠课",
  "中文购买课时": "中文购买课时",
  "中文社交赠课": "中文社交赠课",
  "中文班型": "中文班型",
  "中文班级": "中文班级",
  "中文入学时间": "中文入学时间",
  "中文首课时间": "中文首课时间",
  "数学学生状态": "数学学生状态",
  "数学剩余课时": "数学剩余课时",
  "数学剩余额度": "数学剩余额度",
  "数学当前级别": "数学当前级别",
  "数学顾问": "数学顾问",
  "数学推荐赠课": "数学推荐赠课",
  "数学购买课时": "数学购买课时",
  "数学社交赠课": "数学社交赠课",
  "数学班型": "数学班型",
  "数学班级": "数学班级",
  "数学入学时间": "数学入学时间",
  "数学首课时间": "数学首课时间",
  "中文补升权益": "中文补升权益",
  "数学补升权益": "数学补升权益",
  "中文作业偏好": "中文作业偏好",
  "数学作业偏好": "数学作业偏好",
  "中文跟进策略": "中文跟进策略",
  "数学跟进策略": "数学跟进策略",
  "中文续费池": "中文续费池",
  "数学续费池": "数学续费池",
  "中文近3周课消": "中文近3周课消",
  "数学近3周课消": "数学近3周课消",
  "中文作业提交率": "中文作业提交率",
  "数学作业提交率": "数学作业提交率",
  "中文作业正确率": "中文作业正确率",
  "数学作业正确率": "数学作业正确率",
  "中文本周分享": "中文本周分享",
  "数学本周分享": "数学本周分享",
  "中文近3月进线": "中文近3月进线",
  "数学近3月进线": "数学近3月进线",
  "中文更换班主任数": "中文更换班主任数",
  "数学更换班主任数": "数学更换班主任数",
  "新建群发": "新建群发",
  "群发人群数据正在请求中，您可先编辑群发内容，等待数据请求完成...": "群发人群数据正在请求中，您可先编辑群发内容，等待数据请求完成...",
  "送达人数": "送达人数",
  "执行员工数": "执行员工数",
  "附带专属海报小程序": "附带专属海报小程序",
  "立即提醒": "立即提醒",
  "定时提醒": "定时提醒",
  "中文近3个月进线": "中文近3个月进线",
  "数学近3个月进线": "数学近3个月进线",
  "中文更换班主任次数": "中文更换班主任次数",
  "数学更换班主任次数": "数学更换班主任次数",
  "专属海报": "专属海报",
  "跟进策略": "跟进策略",
  "周期课时信息": "周期课时信息",
  "获客渠道": "获客渠道",
  "获客来源": "获客来源",
  "付款方式": "付款方式",
  "体验周期": "体验周期",
  "转化周期": "转化周期",
  "开课时长": "开课时长",
  "历史沉睡次数": "历史沉睡次数",
  "历史沉睡时长": "历史沉睡时长",
  "近3个月课消": "近3个月课消",
  "近3周课消": "近3周课消",
  "上上周完课": "上上周完课",
  "上周完课": "上周完课",
  "本周已上已约": "本周已上已约",
  "下周已约": "下周已约",
  "辅修课占比": "辅修课占比",
  "最后一次付款月": "最后一次付款月",
  "历史复购次数": "历史复购次数",
  "剩余课时分档": "剩余课时分档",
  "续费池": "续费池",
  "作业信息": "作业信息",
  "近4周作业提交率": "近4周作业提交率",
  "近4周作业正确率": "近4周作业正确率",
  "分享信息": "分享信息",
  "近3个月分享": "近3个月分享",
  "上周分享": "上周分享",
  "本周分享": "本周分享",
  "近3个月进线": "近3个月进线",
  "近3个月节点及时率": "近3个月节点及时率",
  "近3个月节点完成率": "近3个月节点完成率",
  "更换班主任次数": "更换班主任次数",
  "创建订单": "创建订单",
  "结业证书": "结业证书",
  "报告详情": "报告详情",
  "阶段名称": "阶段名称",
  "分类": "分类",
  "作业偏好": "作业偏好",
  "级别（Ⅰ）": "级别（Ⅰ）",
  "级别（Ⅱ）": "级别（Ⅱ）",
  "级别（Ⅲ）": "级别（Ⅲ）",
  "开班时间（北京）": "开班时间（北京）",
  "家长学习期待": "家长学习期待",
  "学生兴趣爱好": "学生兴趣爱好",
  "试学期优惠": "试学期优惠",
  "入学班次": "入学班次",
  "阅读课(北京时间)": "阅读课(北京时间)",
  "已参与": "已参与",
  "未参与": "未参与",
  "体验课详情": "体验课详情",
  "作业详情": "作业详情",
  "作业正确率": "作业正确率",
  "作业提交时间": "作业提交时间",
  "阶段报告": "阶段报告",
  "阶段证书": "阶段证书",
  "答题正确率": "答题正确率",
  "教师评分": "教师评分",
  "上台时长": "上台时长",
  "奖励次数": "奖励次数",
  "举手次数": "举手次数",
  "上课类型": "上课类型",
  "立即排课": "立即排课",
  "请使用已有邮箱进行账号绑定如有疑问请联系IT服务台": "请使用已有邮箱进行账号绑定如有疑问请联系IT服务台",
  "立即绑定": "立即绑定",
  "注册新账号": "注册新账号",
  "此账号暂无登录权限": "此账号暂无登录权限",
  "欢迎": "欢迎",
  "错误": "错误",
  "我已阅读并同意用户协议和隐私政策": "我已阅读并同意用户协议和隐私政策",
  "登录失败：该邮箱还未注册": "登录失败：该邮箱还未注册",
  "登录失败：密码不正确": "登录失败：密码不正确",
  "返回": "返回",
  "该邮箱还未注册": "该邮箱还未注册",
  "立即注册": "立即注册",
  "该邮箱已注册": "该邮箱已注册",
  "忘记密码": "忘记密码",
  "未检测到邮箱录入信息，请联系管理员": "未检测到邮箱录入信息，请联系管理员",
  "好的": "好的",
  "新密码不能和旧密码相同": "新密码不能和旧密码相同",
  "离职申请": "离职申请",
  "主讲教师": "主讲教师",
  "个人资料": "个人资料",
  "账号安全": "账号安全",
  "密码": "密码",
  "修改科目": "修改科目",
  "更换邮箱": "更换邮箱",
  "更换密码": "更换密码",
  "原密码": "原密码",
  "请上传jpg,jpeg,png图片格式": "请上传jpg,jpeg,png图片格式",
  "原密码不正确": "原密码不正确"
}
